.wcm-container {
  z-index: 1500 !important;
}

@font-face {
  font-family: 'Satoshi';
  src: url('https://wefi-web-resources.s3.ap-south-1.amazonaws.com/fonts/Satoshi-Variable.woff2') format('woff2'),
    url('https://wefi-web-resources.s3.ap-south-1.amazonaws.com/fonts/Satoshi-Variable.woff') format('woff'),
    url('https://wefi-web-resources.s3.ap-south-1.amazonaws.com/fonts/Satoshi-Variable.ttf') format('truetype');
    font-weight: 300 900;
    font-display: swap;
    font-style: normal;
  }


@font-face {
  font-family: 'Humanst';
  src: url('./fonts/hmn-regular.woff2') format('woff2'),
    url('./fonts/hmn-regular.woff') format('woff');
    font-weight: 300 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
  font-family: 'Humanst';
  src: url('./fonts/hmn-bold.woff2') format('woff2'),
    url('./fonts/hmn-bold.woff') format('woff');
    font-weight: 700 900;
    font-display: swap;
    font-style: normal;
}


.swiper-wrapper {
  align-items: center;
}
